<template>
    <v-form class="wrapperForm" v-if="dataSource">
        <v-row dense>
            <v-col 
                cols="12" sm="12" md="12" 
                class="section-wrapper section-stepper-wrap"
                v-for="item in dataSource" :key="item.stateid"
            >
                <v-card flat>
                    <v-card-text>
                        <div class="form-box-title">{{ item.processname }}</div>

                        <v-stepper
                            vertical
                            flat
                            tile
                            value="-1"
                        >
                            
                            <template v-for="(itemCh, index) in item.data">
                                <v-stepper-step
                                    :step="index"
                                    complete
                                    :color="itemCh.status != null ? $options.statusColor[itemCh.status] : '#00000061'"
                                    :complete-icon="getStepIcon(itemCh.name, itemCh.status)"
                                    :key="itemCh.id + '_step'"
                                >
                                    <div v-tooltip.top-center="itemCh.message" class="elipsis-text-wrap">
                                        <div>
                                            {{ dateFormat(itemCh.datetime, 'DD.MM.YYYY HH:mm' ) }}

                                            <v-chip 
                                                v-if="itemCh.status != null"
                                                class="table-label"
                                                small
                                                label
                                                dark
                                                :color="$options.statusColor[itemCh.status]" 
                                            >
                                                <div>{{ $options.statusKeys[itemCh.status] }}</div>
                                            </v-chip>

                                            {{ $t(itemCh.name) }}
                                            {{ itemCh.message && itemCh.message.length ? `, Сообщение: ${itemCh.message}`: '' }}
                                        </div>
                                    </div>
                                </v-stepper-step>

                                <v-stepper-content 
                                    :step="index"
                                    :key="itemCh.id + '_content'"
                                ></v-stepper-content>
                            </template>
                                                   
                        </v-stepper>
                        

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from '@/services/system';

export default {
    name: "routes-tab",
    props: {
        id: String,
    },
    statusColor: {
        'Running': '#9B84EE',
        'Success': '#43B581',
        'Warning': '#FAA61A',
        'Error': '#F04747',
        'Cancelled': '#00000061',
        'Passed': '#00000061',
    },
    statusKeys: {
        "Running": "Исполняется",
        "Success": "Успешно",
        "Warning": "Предупреждение",
        "Error": "Ошибка",
        "Cancelled": "Отменено",
        "Passed": "Пропущен"
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null
        }
    },
    methods: {
        async update() {

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `/api/tabs/processesv2/${this.$props.id}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            
            console.log(response.data.payload);
            if (response.data.success)
            {
                let processes = this.getSortedProcesses(response.data.payload)
                processes.forEach(p => {
                    p.data = this.getSortedProcessData(p.data)
                })

                this.dataSource = processes;
            }
        },
        isFirstInGroup(item) {
            let group = this.dataSource.find(x => x.children.find(child => child.id === item.id) != null );
            return group.children.indexOf(item) === 0;
        },
        isLastInGroup(item) {
            let group = this.dataSource.find(x => x.children.find(child => child.id === item.id) != null );
            return group.children.indexOf(item) + 1 === group.children.length;
        },
        dateFormat(value, format, inputFormat)
        {
            return sys.dateFormat(value, format, inputFormat);
        },
        getStepIcon(nameStep, status)
        {
            if(nameStep == 'Avrora.Objects.Modules.WorkFlow.Activities.StartActivity'){
                return 'mdi mdi-cast-audio-variant';
            }else if(nameStep == 'Avrora.Objects.Modules.WorkFlow.Activities.EndActivity'){
                return 'fas fa-flag-checkered';
            }else{
                
                switch (status) {
                    case 'Running':
                        return 'mdi mdi-progress-pencil';
                    case 'Success':
                        return 'mdi mdi-check';   
                    case 'Error':
                        return 'mdi mdi-alert'; 
                    case 'Warning':
                        return 'mdi mdi-progress-alert';   
                    case 'Cancelled':
                        return 'mdi mdi-cancel'; 
                    case 'Passed':
                        return 'mdi mdi-minus-circle-outline';      
                    default:
                        return '';
                }
            }
        },        
        getSortedProcesses(processes)
        {
            return processes.sort((p1, p2)=> {
                let p1IsCompleteNull = p1.data.find(x => x.name === "Avrora.Objects.Modules.WorkFlow.Activities.EndActivity")?.iscomplete === null;
                let p2IsCompleteNull = p2.data.find(x => x.name === "Avrora.Objects.Modules.WorkFlow.Activities.EndActivity")?.iscomplete === null;

                console.log('p1IsCompleteNull', p1IsCompleteNull, 'p2IsCompleteNull', p2IsCompleteNull)
                if (p1IsCompleteNull == p2IsCompleteNull)
                    return 0;
                else if (p1IsCompleteNull)
                    return -1;
                else if (p2IsCompleteNull)
                    return 1;
            });
        },
        getSortedProcessData(data)
        {
            let result = [];
            let startActivity = data.find(x => x.name === "Avrora.Objects.Modules.WorkFlow.Activities.StartActivity");
            let endActivity = data.find(x => x.name === "Avrora.Objects.Modules.WorkFlow.Activities.EndActivity");

            this.fillActivities(result, startActivity, data, endActivity.iscomplete);

            result.push(endActivity);
            return result;
        },
        fillActivities(result, currentActivity, data, iscomplete)
        {
            if (iscomplete && currentActivity.iscomplete == null)
                currentActivity.status = "Passed";

            result.push(currentActivity);                

            let filtered = data.filter(x => currentActivity.ids.filter(i => i != 2).includes(x.id));

            filtered.forEach(x => {
                this.fillActivities(result, x, data, iscomplete);
            });
        },            
    },
    async created() {
        await this.update();
    },
    mounted() {        
        this.$eventBus.$on('update-active-tab', async () => {
            if (this.$parent.isActive)
                await this.update();
        });
    },
    destroyed() {
        this.$eventBus.$off('update-active-tab');
    }
}
</script>
<style>
    .section-stepper-wrap .v-stepper--vertical{
        padding: 0!important;
    }
    .section-stepper-wrap .error--text:not(.v-messages)::after, .chipAreaNoData::after{
        display: none;
    }
    .section-stepper-wrap .v-stepper__step__step .v-icon.fas{
        font-size: .75rem!important;
    }
    .section-stepper-wrap .v-stepper__label small{
        margin-top: 4px;
    }
    .section-stepper-wrap .v-stepper--vertical .v-stepper__step{
        padding: 0!important;
    }
    .section-stepper-wrap .v-stepper--vertical .v-stepper__step:first-child {
        padding-top: 5px!important;
    }
    .section-stepper-wrap .v-stepper--vertical .v-stepper__content{
        margin: 8px 12px 8px 12px!important;
    }
    .section-stepper-wrap .v-stepper--vertical .v-stepper__content:last-child{
        display: none;
    }
</style>